import React from 'react';
import { Typography, Button, makeStyles } from "@material-ui/core";

const openApp = () => {
  window.open('https://bingest.app/app', '_blank');
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  homeContent: {
    position: 'relative',
    width: '100%',
    zIndex: 999,
  },
  titleContent: {
    backgroundColor: 'rgba(28, 28, 28, 0.7)',
    maxWidth: 1024,
    display: 'flex',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
    padding: 16,
    transform: 'translateY(100%)',
  },
  imageContent: {
    zIndex: 0,
    position: 'absolute',
    right: '40%',
  },
  '@media screen and (max-width: 1024px)': {
    titleContent: {
      justifyContent: 'flex-start',
    },
    imageContent: {
      right: 50,
    },
  },
  '@media screen and (max-width: 430px)': {
    titleContent: {
      justifyContent: 'flex-start',
      transform: 'translateY(50%)',
    },
    imageContent: {
      right: 30,
      transform: 'translateY(-10%)',
    },
  },
  '@media screen and (max-width: 320px)': {
    titleContent: {
      padding: '10px 0px',
      transform: 'translateY(50%)',
    },
    imageContent: {
      transform: 'translateY(-10%)',
      right: 20,
    },
  },
});

function Home() {
const styles = useStyles();
let imageSize = 320;
if (window.innerWidth < 430) {
  imageSize = 200;
}


return  <div className={styles.container}>
        <div className={styles.imageContent}>
          <img decoding="async" src="./bingest_usecase.gif" alt="usecase" width={imageSize}/>
        </div>
         <div className={styles.homeContent}>
          <div className={styles.titleContent}>
            <div>
              <div>
                <Typography
                  color="textPrimary"
                  variant="h1"
                >
                  Bingest
                </Typography>
              </div>
              <div>
                <div>
                  <Typography
                    color="textPrimary"
                    variant="caption"
                  >
                    観たい動画を一括管理
                  </Typography>
                </div>
                <div>
                  <Typography
                    color="textPrimary"
                    variant="caption"
                  >
                    もう期待の作品を観逃さない
                  </Typography>
                </div>
              </div>
              <div>
                <Button
                  variant="contained"
                  onClick={openApp}
                >
                  使ってみる
                </Button>
              </div>
            </div>
          </div>
          </div>
        </div>
}

export default Home;
