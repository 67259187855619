import React from 'react';
import { makeStyles, Card, CardHeader, IconButton, CardContent } from "@material-ui/core";
import { Link } from 'react-router-dom';
import  BackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles({
  root: {
    marginBottom: 100,
    minWidth: 300,
    maxWidth: 840,
    width: '95%'
  },
  content: {
    width: '100%',
    height: 400,
    overflowY: 'scroll'
  }
});

const termStr = `
利用規約

この利用規約（以下，「本規約」といいます。）は，aknow2（以下，「当社」といいます。）が提供するサービス（以下，「サービス」といいます。）またはアプリケーション（以下，「アプリ」といいます。）の利用条件を定めるものです。
ご利用のユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスやアプリケーションをご利用いただきます。また、アプリやサービスを使用するために必要な携帯端末、通信機器、通信手段等は、利用者の費用と責任で備えるものとします。


第1条（適用）
・本規約は，ユーザーと当社との間のサービスとアプリの利用に関わる一切の関係に適用されるものとします。
・本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。

第2条（利用）
・ユーザーは、本規約の内容に承諾したうえで、アプリやサービスを利用するものとします。
また、本規約は利用者がアプリやサービスを利用した日をもってその効力を発するものとします。
・登録が必要なサービスやアプリに関しては、希望者が当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。
当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
（1）利用登録の申請に際して虚偽の事項を届け出た場合
（2）本規約に違反したことがある者からの申請である場合
（3）その他，当社が利用登録を相当でないと判断した場合


第3条（ユーザーIDおよびパスワードの管理）
ユーザーは，自己の責任において，本サービスやアプリのユーザーIDおよびパスワードを管理するものとします。
 ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与することはできません。
 当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。


第4条（広告）
アプリやサービスに表示される広告・宣伝を行っている広告主との取引は、ユーザーと該当広告主の責任において行ってください。
当社は、アプリやサービスに掲載される広告によって発生した損害及び掲載された事実に起因する損害に関しては一切の責任を負いません。
また、当社は広告枠及び広告表示の位置、表示期間等の仕様に関して、予告なく変更を行う場合があります。


第5条（禁止事項）
ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。

（1）法令または公序良俗に違反する行為
（2）犯罪行為に関連する行為
（3）当社のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
（4）当社のサービスの運営を妨害するおそれのある行為
（5）他のユーザーに関する個人情報等を収集または蓄積する行為
（6）他のユーザーに成りすます行為
（7）当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
（8）当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為
（9）その他，当社が不適切と判断する行為


第6条（本サービスの提供の停止等）
当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。

（1）本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
（2）地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
（3）コンピュータまたは通信回線等が事故により停止した場合
（4）その他，当社が本サービスの提供が困難と判断した場合

当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害について，理由を問わず一切の責任を負わないものとします。


第7条（利用制限および登録抹消）
当社は，以下の場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。

（1）本規約のいずれかの条項に違反した場合
（2）登録事項に虚偽の事実があることが判明した場合
（3）その他，当社が本サービスの利用を適当でないと判断した場合
当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。


第8条（免責事項）
当社の債務不履行責任は，当社の故意または重過失によらない場合には免責されるものとします。
当社は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。


第9条（サービス内容の変更等）
当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。

第10条（個人情報の取扱い）
当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。

第11条（利用規約の変更）
当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。


第12条（通知または連絡）
ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。


第13条（権利義務の譲渡の禁止）
ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。


第14条（準拠法・裁判管轄）
本規約の解釈にあたっては，日本法を準拠法とします。 本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。


以上 2020年5月 制定
`;


const policyStr = `
プライバシーポリシー

aKnow2（以下、「当社」といいます。）は、当社が提供する全てのスマートフォン用アプリケーション、およびWebサービスに関するサービス（以下、「サービス」といいます。）を通じて、 お客様の利用者情報をご提供いただき、本サービスの円滑な提供を実施させていただくために、アプリケーション・プライバシーポリシーを定め、利用者情報の保護に努めます。


また、お客様が本アプリケーションによる利用者情報の提供をご希望されない場合は、お客様ご自身のご判断により、利用者情報の提供を拒否することができます。 この場合、本アプリケーションおよび本サービスをご利用になれない場合がありますので、予めご了承ください。


1. 法令等の順守について
当社は、個人情報保護の実現のため、個人情報保護法、各省庁ガイドラインその他関連する法令等を順守いたします。


2. 目的
当社は、当社のサービスとアプリケーションの運営に必要な範囲で個人情報を保有します。当社における具体的な個人情報の利用目的は次のとおりです。これら以外の目的に利用することはありません。

・サービスの運営およびこれらに付帯・関連するサービスの提供


3. 個人情報の第三者への提供
当社は、次の場合を除いて、ご本人の同意なく個人情報を第三者へ提供することはありません。

・裁判所・警察等の公的機関から、法令に基づく正式な照会要請を受けた場合。
・人の生命、身体および財産等に対し差し迫った危険があり、緊急の必要性があると認められる場合。
・利用者が希望するサービスの提供のため、情報の開示や共有が必要と認められる場合。
・利用者にサービスを提供する目的で当社から委託を受けて業務を行う会社が情報を必要とする場合。(こうした会社は当社が提供した個人情報を上記目的の必要限度を超えて利用することはできません。)
・利用者の行為が、利用規約に反し、当社および第三者の権利・財産やサービス等を保護するため必要と認められる場合。
・利用者がご本人の個人情報を第三者へ提供することにつき同意をされた場合。
・当社のサービスでは、サイトの分析と改善のためにGoogleアナリティクスを使用しています。
Googleアナリティクスの作成会社であるGoogle Inc.に特定の情報（たとえば、アクセスしたページのウェブ アドレスや IP アドレスなど）を自動的に送信します。
また、データ収集のためにGoogle Inc. がお使いのブラウザに cookie を設定したり、既存のcookieを読み取ったりする場合があります


4.利用者情報の取り扱いに関する問い合わせ窓口
本アプリケーションおよび本サービスにおける利用者情報の取扱いに関して、ご意見・ご要望がございましたら、下記窓口までご連絡くださいますようお願いします。

■お問い合わせ方法：tmknym@aknow2.com


5.本アプリケーション・プライバシーポリシーの変更
当社は、法令の変更等に伴い、本アプリケーション・プライバシーポリシーを変更することがあります。 当社は、本アプリケーションのバージョンアップに伴って、利用者情報の取得項目の変更や追加、利用目的の変更、第三者提供等について変更がある場合には、本アプリケーション・プライバシーポリシーにて通知いたします。


6. 個人データの安全管理措置
当社は、ご本人の個人情報を正確、最新なものにするよう常に適切な処置を講じています。 また、ご本人の個人情報への不当なアクセス、個人情報の紛失・破壊・改ざん・漏えいなどを防止するため、万全を尽くしています。 なお、当社の委託を受けて個人情報を取り扱う会社にも、同様に厳重な管理を行わせています。万一、個人情報に関する事故が発生した場合には、迅速かつ適切に対応いたします。

以上 2020年5月 制定
`;


type TermType = 'terms' | 'policy';

interface Props {
  type: TermType;
}

function Terms(props: Props) {
  const contentStr = props.type === 'terms' ? termStr : policyStr;
  const styles = useStyles();
  return <Card className={styles.root}>
            <CardHeader
              avatar={
                <Link to='/'>
                  <IconButton>
                    <BackIcon />
                  </IconButton>
                </Link>
              }
              title={props.type === 'terms' ? '利用規約':'プライバシーポリシー'}
            />
            <CardContent>
              <div  className={styles.content}>
            {contentStr.split('\n').map(str => <p>{str}</p>)}
              </div>
            </CardContent>
          </Card>
}

export default Terms;

