import React from 'react';
import { makeStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import * as router from 'react-router-dom';
import Home from './home';
import Terms from './terms';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: 10
  }
});

function Main() {
  const styles = useStyles();
  return (
    <router.BrowserRouter>
      <div className={styles.root}>
        <router.Switch>
        <router.Route path="/" exact={true} >
          <Home />
        </router.Route>
        <router.Route path="/terms">
          <Terms type="terms" />
        </router.Route>
        <router.Route path="/policy">
          <Terms type="policy" />
        </router.Route>
        </router.Switch>
        <footer className={styles.footer}>
          <Link color="textPrimary" underline="always" href='/policy'>
            プライバシーポリシー
          </Link>
          <Link color="textPrimary" underline="always" href='/terms'>
            利用規約
          </Link>
          <Link
            href="https://twitter.com/aknow21"
            color="textPrimary"
            underline="always"
          >
            運営
          </Link>
        </footer>
      </div>
    </router.BrowserRouter>
  );
}

export default Main;
