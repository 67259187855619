import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Main from './main';

function App() {
  const theme = React.useMemo(
    () => createMuiTheme({
      palette: {
        type: 'dark',
      },
    }),
    []
  );
  return (
    <ThemeProvider theme={theme}>
      <Main />
    </ThemeProvider>
  );
}

export default App;
